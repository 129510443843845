<template>
  <section>
      <div class="content-header">
        <h2>Adopting resilient pedagogy  </h2>
      </div>
      <div class="content-wrapper">
        <p>This resource from Carleton College offers a foundational view of <a href="https://www.carleton.edu/ltc/resilient-pedagogy/" target="_blank">how to cultivate academic resilience</a> in your courses.</p>
        <!-- <a href="https://www.carleton.edu/ltc/resilient-pedagogy/" target="_blank">
          <img src="@/assets/img/_content/academic-design-4.png" class="img-fluid img-thumb" alt="" srcset="">
        </a> -->
        <!-- <div class="row align-items-center">
          <div class="col-sm-6">
            <p>This resource offers a foundational view of <a href="https://www.carleton.edu/ltc/resilient-pedagogy/" target="_blank">how to cultivate academic resilience</a> in your courses.</p>
          </div>
          <div class="col-sm-6">
            <img src="@/assets/img/_content/academic-design-4.png" class="img-fluid img-thumb" alt="" srcset="">
          </div>
        </div> -->
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '04',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
